<template>
  <div class="wgt_productShelves_attr-item">
    <div class="attr-item-header">
      <div class="attr-panel-group-title">商品数据设置</div>
      <div :class="{ 'attr-item-header_link-disabled': true }" @click="handleEditGoods">
        {{ getName() }}
        <el-button icon="el-icon-edit" type="text" />
      </div>
    </div>
    <SpDialog width="950px" title="选择商品" ref="goodsDialogRef" v-model="goodsDialog" :modal="false" :form="goodsForm"
      :form-list="goodsFormList" @onSubmit="onGoodsFormSubmit" class="dialog-goods" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import CompHotPicker from '../../comps/comp-hotpicker'
import hotzone from 'vue-hotzone'
import { isArray } from '@/utils'
export default {
  props: {
    value: {
      type: Object,
      default: () => null
    },
    uuid: {
      type: String,
      default: ''
    },
    showType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: null,
      goodsDialog: false,
      refresh: true,
      loading: true,
      categoryList: [],
      goodsForm: {
        shelfType: 'rule',
        data: [],
        imgUrl: "",
        hotdata: [],
        num: ''
        // rule: 0,
        // goodsData: '',
        // classify: [],
        // resource: 0,
        // strategy: "",
        // parameter: '',
        // undertake: false,
      },
      aiOptions: [],
      ruleOptions: [{
        label: 0,
        name: '按系统配置'
      }, {
        label: 1,
        name: '按商品分类'
      }],
      resourceOptions: [{
        label: 2,
        name: '按系统配置'
      }, {
        label: 3,
        name: '按神策分析'
      }],
      shelfTypeOptions: [{
        label: 'rule',
        name: '商品规则'
      },
      {
        label: 'recommend',
        name: '商品推荐'
      },
      {
        label: 'history',
        name: '用户浏览记录'
      }],
      ruleSourceOptions: [{
        label: 'style',
        name: '按系统配置'
      }, {
        label: 'classify',
        name: '按商品分类'
      }],
      sourceOptions: [
        {
          label: 1,
          name: '按神策分析'
        }]
    }
  },
  // {
  //               name: '显示商品自带营销信息',
  //               key: 'discount',
  //               render: (h, { row }) => {
  //                 return <el-checkbox v-model={row.selfInfo} >显示商品自带营销信息</el-checkbox>
  //               }
  //             }, 
  computed: {
    setting() {
      return {
        columns: [{ name: '商品ID', key: 'goods_id', width: '70' },
        {
          name: '商品名称', key: 'item_name', render: (h, { row }) =>
            h(
              'div',
              {
                class: 'item-info'
              },
              [
                h('el-image', {
                  class: 'item-image',
                  props: { src: row.pics[0], fit: 'cover' }
                }),
                h('div', { class: 'item-name-wrap' }, [
                  h('div', { class: 'item-name' }, row.itemName),
                  h('div', { class: 'item-sku' }, row.nospec ? '' : '多规格')
                ])
              ]
            )
        }, {
          name: '显示以旧换新折扣',
          key: 'discount',
          render: (h, { row }) => {
            return <el-checkbox v-model={row.discount} >显示以旧换新折扣</el-checkbox>
          }
        },

        {
          name: '营销信息',
          key: 'marketingInfo',
          render: (h, { row }) => {
            return !row.selfInfo ? <el-input v-model={row.marketingInfo} size='mini' /> : ''
          }
        }
        ]
      }
    },
    goodsFormList() {
      const showType = this.showType
      const { shelfType, data, num, shownum, imgUrl, hotdata, source } = this.goodsForm;
      return [{
        label: '货架类型',
        key: 'shelfType',
        type: 'radio-button',
        options: this.shelfTypeOptions,
        onChange: (e) => {
          this.loading = false
          this.$set(this.goodsForm, 'data', [])
          this.$set(this.goodsForm, 'imgUrl', '')
          this.$set(this.goodsForm, 'hotdata', [])
          this.$set(this.goodsForm, 'num', 0)
          this.$set(this.goodsForm, 'shownum', 0)
          if (e == 'rule') {
            this.$set(this.goodsForm, 'source', 'style')
          }
          this.$nextTick(() => {
            this.loading = true
          })
        }
      }, {
        label: '商品来源',
        isShow: shelfType == 'rule',
        key: 'source',
        type: 'radio',
        options: this.ruleSourceOptions,
        onChange: (e) => {
          if (e == 'classify') {
            this.getCategory()
            this.$set(this.goodsForm, 'data', [])
            this.$set(this.goodsForm, 'categoryId', '')
            this.$set(this.goodsForm, 'showClassify', false)
            this.$set(this.goodsForm, 'showParam', false)
          }
        }
      }, {
        label: '商品分类',
        isShow: shelfType == 'rule' && source == 'classify',
        key: 'categoryId',
        type: 'select',
        options: this.categoryList,
      }, {
        label: '分类筛选',
        isShow: shelfType == 'rule' && source == 'classify',
        key: 'showClassify',
        type: 'switch'
      }, {
        label: '参数筛选',
        isShow: shelfType == 'rule' && source == 'classify',
        key: 'showParam',
        type: 'switch'
      }, {
        label: '',
        isShow: shelfType == 'rule' && source == 'style',
        key: 'data',
        component: ({ key }) => {
          return <div class='attr-goods-item'>
            <el-button type="primary" size='mini' onClick={this.handleSelectGoodsPicker}>选择商品</el-button>
            {this.refresh && this.goodsDialog && <SpFinder no-selection={true} ref='goodsFinderRef' show-pager={false} data={this.goodsForm[key]} setting={this.setting} />}
          </div>
        }
      }, {
        label: '商品来源',
        isShow: shelfType == 'recommend',
        key: 'source',
        type: 'radio',
        options: this.sourceOptions,
        onChange: (e) => {
          if (e == 1) {
            this.getOptions()
          }
        }
      }, {
        label: '资源位',
        isShow: shelfType == 'recommend' && source == 1,
        key: 'sourceposition',
        type: 'select',
        options: this.aiOptions,
      },
      {
        label: '最大商品数',
        key: 'num',
        isShow: shelfType === 'history' || (shelfType == 'rule' && source == 'classify'),
        type: 'number',
        position: 'right',
        max: shelfType === 'history'?10:100,
        min: 0,
        onChange: (e) => {
          if (shownum > e) {
            this.$set(this.goodsForm, 'shownum', e)
          }
        }
      },
      {
        label: '展示商品数',
        key: 'shownum',
        isShow: showType == 'vertical',
        type: 'number',
        position: 'right',
        min: 0,
        max: shelfType == 'rule' || shelfType === 'history' ? shelfType === 'history' || (shelfType == 'rule' && source == 'classify') ? num : data.length : 1000,
        tip: '设置展示数小于商品总数时，纵向宫格样式下将展示查看更多按钮'
      }, {
        label: '无数据展示',
        key: 'empty',
        isShow: shelfType === 'history',
        component: ({ key }) => {
          return <div class='empty-item'>
            <el-button type="primary" size="small" plain onClick={() => this.onSelectImage()}> 选择图片 </el-button>
            {this.loading && <hotzone
              class="hotzone"
              image={imgUrl || ''}
              zones-init={hotdata}
              onAdd={() => this.handleAdd()}
              onChange={(e) => this.handleChange(e)}
              onRemove={(e) => this.handleRemove(e)}
            />}
            {this.loading && <div class="zone-item">
              {hotdata?.map((item, index) => {
                return <div class='zone-item-picker'>
                  <span>热区{index + 1}:</span>
                  <CompHotPicker key={`zone-item__${index}`} value={item} on-change={(e) => this.onChangeLink(e, index)} wgtType="hotzone" />
                </div>
              })}
            </div>}
          </div>
        }
      }]
    }
  },
  methods: {
    async getCategory() {
      const res = await this.$api.goods.getCategory()
      this.categoryList = res.map(item => {
        return {
          label: item.category_name,
          value: item.category_id
        }
      })
    },
    async getOptions() {
      const data = await this.$api.template.getwebSections({ num: 1000, type: 'FEED' })
      this.aiOptions = data.map(item => {
        return {
          label: item.cname,
          value: item.section_id
        }
      })
    },
    //展示文案
    getName() {
      const item = this.shelfTypeOptions.find(item => item.label == this.goodsForm.shelfType)
      return item?.name
    },
    // 选择无数据展示图片
    async onSelectImage() {
      this.loading = false
      const {
        data: { url }
      } = await this.$picker.image({
        data: { url: this.goodsForm.imgUrl }
      })
      this.$set(this.goodsForm, 'imgUrl', url)
      this.$nextTick(() => {
        this.loading = true
      })
    },
    //添加热区
    handleAdd() {
      let item = {
        linkPage: '',
        title: '',
        id: '',
        card_type: '',
        goods_id: ''
      }
      if (!this.goodsForm.hotdata) {
        this.goodsForm.hotdata = [item]
        return
      }
      this.goodsForm.hotdata.push(item)
    },
    // 修改热区点击事件的数据
    onChangeLink(e, index) {
      const oldVal = this.goodsForm.hotdata[index]
      this.$set(this.goodsForm.hotdata, index, Object.assign(oldVal, e))
    },
    // 修改热区设置(宽高位置)
    handleChange(zone) {
      setTimeout(() => {
        zone.forEach(({ heightPer, widthPer, leftPer, topPer }, index) => {
          if (!this.goodsForm.hotdata[index]) return
          const v = cloneDeep(this.goodsForm.hotdata[index])
          const obj = {
            heightPer,
            leftPer,
            topPer,
            widthPer
          }
          this.$set(this.goodsForm.hotdata, index, Object.assign(v, obj))
        })
      }, 500)
    },
    // 删除热区
    handleRemove(index) {
      this.ischange = false
      this.goodsForm.hotdata.splice(index, 1)
    },
    // 保存设置商品
    onGoodsFormSubmit() {
      this.localValue = cloneDeep(this.goodsForm)
      this.goodsDialog = false
      this.$nextTick(() => {
        this.$emit('input', this.localValue)
      })
    },
    // 设置商品弹窗打开
    handleEditGoods() {
      this.goodsDialog = true
      this.$set(this.goodsForm, 'shelfType', cloneDeep(this.localValue.shelfType))
      this.$set(this.goodsForm, 'data', cloneDeep(this.localValue.data))
      this.$set(this.goodsForm, 'imgUrl', cloneDeep(this.localValue.imgUrl))
      this.$set(this.goodsForm, 'hotdata', cloneDeep(this.localValue.hotdata))
      this.$set(this.goodsForm, 'num', cloneDeep(this.localValue.num))
    },
    //选择商品
    async handleSelectGoodsPicker() {
      try {
        const { data } = await this.$picker.goodsitem({
          data: this.goodsForm.data,
          multiple: true
        })
        this.refresh = false
        if (data) {
          this.$set(this.goodsForm, 'data', cloneDeep(data))
          if (this.goodsForm.shownum > data.length) {
            this.$set(this.goodsForm, 'shownum', data.length)
          }
          this.refresh = true
        }
      } catch (e) {
      }

    },
  },
  watch: {
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
        const { hotdata, ...item } = this.localValue
        if (this.localValue.source == 1) {
          this.getOptions()
        }
        if (this.localValue.source == 'classify') {
          this.getCategory()
        }
        this.goodsForm = cloneDeep({
          ...item,
          hotdata: isArray(hotdata) ? hotdata : []
        })
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.wgt_productShelves {
  &_attr-item {
    margin-left: -80px;

    .attr-item {
      &-title {
        font-size: 14px;
        color: #333;
        margin-bottom: 10px;
      }

      &-header {
        margin-left: -10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_link {
          height: 30px;
          line-height: 30px;
          box-sizing: border-box;
          color: #999 !important;

          &-disabled {
            color: #409EFF;
            cursor: pointer;
            justify-content: space-between;
            display: flex;
            align-items: center;
          }
        }
      }


    }
  }
}
</style>
<style lang="scss">
.dialog-goods {

  .el-form {
    margin-right: 0px !important;
  }

  .attr-goods-item {
    margin-left: -80px;
  }

  .sp-finder {
    max-height: 400px;
    overflow-y: scroll;
  }

  .item-info {
    display: flex;

    .item-image {
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }

    .item-name {
      color: #333;
      margin-top: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 120px;
      flex-shrink: 0;
      min-width: 120px;
    }

    .item-sku {
      font-size: 12px;
      background-color: #f0f2f5;
      display: inline-block;
      padding: 0 4px;
      line-height: 18px;
    }
  }

  .empty-item {
    .zone-item {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      &-picker {
        margin-right: 10px;
        display: flex;
        align-items: center;
        margin-top: 10px;

        span {
          margin-right: 5px;
        }
      }
    }

    .hotzone {
      .hz-u-img {
        object-fit: contain;
        max-height: 300px;
      }

    }

  }
}
</style>