import { pickBy } from "@/utils";
import BorderItem from "./border-item.vue";
import SettingStyle from "./setting-style.vue";
import ConfigAttr from "./config-attr";
import AttrItem from "./attr-item.vue";
import RelateConfig from "./relate-config.vue";
import CompsStyle from "../../comps/comps-style.vue";
import CompColor from "../../comps/comp-color";
import CompBg from "../../comps/comp_bg";
import CompsRadius from "../../comps/comps-radius";

import { shelvesConfig } from "../const.js";
import { isArray, isUndefined } from "lodash-es";
let goodsConfig = {};
Object.keys(shelvesConfig).forEach((key) => {
  goodsConfig[key] = { ...shelvesConfig[key], independent: false };
});

export default {
  name: "productShelves",
  setting: [
    {
      label: "组件背景",
      key: "combg",
      component: function (h, { key }) {
        return <CompBg v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        bgType: "color",
        color: "#f5f5f7",
        imageUrl: "",
      },
    },
    {
      label: "内边距",
      key: "compadded",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedt: 10,
        paddedb: 10,
        paddedl: 10,
        paddedr: 10,
      },
    },
    {
      label: "圆角",
      key: "radius",
      value: {
        upleft: 0,
        upright: 0,
        bottomleft: 0,
        bottomright: 0,
      },
      component: function (h, { key }) {
        return (
          <CompsRadius
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showUpleft
            showUpright
            showBottomleft
            showBottomright
          />
        );
      },
    },
    {
      label: "内容背景色",
      key: "contentcolor",
      component: "color",
      size: "mini",
      value: '#fff',
    },
    {
      label: "展示形式",
      key: "show_type",
      component: "radiobutton",
      options: [
        { name: "纵向滚动 ", label: "vertical" },
        { name: "横向滚动", label: "" },
      ],
      value: "vertical",
    },

    // {
    //   label: "门店",
    //   key: "showShelves",
    //   component: "switch",
    //   value: false,
    //   module: "store",
    //   isShow: function () {
    //     return false;
    //   },
    // },
    // {
    //   label: "门店信息",
    //   key: "storestyle",
    //   component: function (h, { key }) {
    //     return <BgItem v-model={this.value[key]} uuid={this.value.uuid} />;
    //   },
    //   value: {
    //     bgType: "color",
    //     color: "#fff",
    //     imageUrl: "",
    //   },
    //   isShow: function () {
    //     return false;
    //   },
    //   module: "store",
    //   isShow: function () {
    //     return this.value.showShelves;
    //   },
    // },
    // {
    //   label: "门店货架",
    //   key: "open_store",
    //   component: "radiobutton",
    //   options: [
    //     { name: "开启", label: true },
    //     { name: "关闭 ", label: false },
    //   ],
    //   value: true,
    //   module: "store",
    //   isShow: function () {
    //     return false
    //   },
    // },
    {
      label: "进度条",
      key: "showProgress",
      component: "radiobutton",
      options: [
        { name: "开启", label: true },
        { name: "关闭 ", label: false },
      ],
      value: true,
      isShow: function () {
        return !this.value.show_type;
      },
      module: "",
    },
    {
      label: "进度条下边距",
      key: "processh",
      component: "number",
      position: "right",
      size: "mini",
      value: 10,
      min: 0,
      isShow: function () {
        return !this.value.show_type && this.value.showProgress;
      },
      module: "",
    },
    {
      label: "进度条颜色",
      key: "pilot",
      component: function (h, { key }) {
        return <CompColor v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      isShow: function () {
        return !this.value.show_type && this.value.showProgress;
      },
      value: {
        dot_noCover: "#000",
        dot_cover: "#fff",
      },
      module: "",
    },
    {
      label: "",
      key: "shelvesData",
      component: function (h, { key }) {
        return <AttrItem v-model={this.value[key]} uuid={this.value.uuid} showType={this.value.show_type} />;
      },
      value: {
        shelfType: "rule",
        source: 'style',
        data: [],
        imgUrl: "",
        hotdata: [],
        num: "",
        shownum: 10
      },
      module: "shelvesData",
    },
    {
      label: "",
      key: "shelvesLink",
      component: function (h, { key }) {
        return <RelateConfig v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        device_recommend: '',
        device_recommend_img: '',
        device_compare: '',
        device_compare_img: '',
      },
      isShow: function () {
        return this.value.show_type
      },
      module: "shelvesData",
    },
    {
      label: "宽度",
      key: "width",
      component: "number",
      position: "right",
      value: 175,
      module: "goodsItem",
      isShow: function () {
        return !this.value.show_type;
      },
    },
    {
      label: "背景",
      key: "itembg",
      isShow: function () {
        return false;
      },
      component: function (h, { key }) {
        return <CompBg v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        bgType: "color",
        color: "#fff",
        imageUrl: "",
      },
      module: "goodsItem",
    },
    {
      label: "内边距",
      key: "itempadded",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedt: 10,
        paddedb: 10,
        paddedl: 10,
        paddedr: 10,
      },
      module: "goodsItem",
    },
    {
      label: "外边距",
      key: "itemmargin",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showTop={false}
            showLeft={false}
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedr: 0,
        paddedb: 0,
      },
      module: "goodsItem",
    },
    {
      label: "图片宽度",
      key: "imgWidth",
      component: "number",
      position: "right",
      size: "mini",
      value: 100,
      max: 100,
      append: "%",
      min: 0,
      module: "goodsItem",
    },
    {
      label: "图下边距",
      key: "imgPaddedBottom",
      component: "number",
      position: "right",
      size: "mini",
      value: 10,
      min: 0,
      module: "goodsItem",
    },
    {
      label: "边框",
      key: "border",
      component: function (h, { key }) {
        return <BorderItem v-model={this.value[key]} uuid={this.value.uuid} />;
      },
      value: {
        borderWidth: 0,
        borderColor: "#000",
        borderRadius: 0,
      },
      module: "goodsItem",
    },
    {
      label: "商品样式",
      key: "setting",
      component: function (h, { key }) {
        return (
          <SettingStyle
            v-model={this.value[key]}
            shelfType={this.value.shelvesData.shelfType}
            uuid={`${this.value.uuid}_${this.value.uuid}`}
            change={(e) => {
              this.$set(this.value, "setting", e);
            }}
            title="商品样式"
          />
        );
      },
      value: shelvesConfig,
      module: "goodsItem",
    },
    {
      label: "",
      key: "goodsSetting",
      component: function (h, { key }) {
        return (
          <ConfigAttr
            shelfType={this.value.shelvesData.shelfType}
            v-model={this.value[key]}
            uuid={`${this.value.uuid}`}
          />
        );
      },
      value: [goodsConfig, goodsConfig, goodsConfig, goodsConfig],
      module: "goodsItem",
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base: { compadded, itempadded, itemmargin, ...base },
      config,
      setting: { defaultSetting, goodsSetting, shelvesLink },
      data: { data, shownum, source, ...shelvesData },
    } = v;
    let _source = source || 'style'
    const link = isUndefined(shelvesLink) ? { device_recommend: '', device_compare: '', device_compare_img: '', device_recommend_img: '' } : shelvesLink
    //兼容新增字段shownum
    const l = isArray(data) ? data.length : 0
    const num = isUndefined(shownum) ? l : shownum;
    return {
      name,
      compadded: pickBy(compadded, {
        paddedt: "top",
        paddedb: "bottom",
        paddedl: "left",
        paddedr: "right",
      }),
      itempadded: pickBy(itempadded, {
        paddedt: "top",
        paddedb: "bottom",
        paddedl: "left",
        paddedr: "right",
      }),
      itemmargin: pickBy(itemmargin, {
        paddedb: "bottom",
        paddedr: "right",
      }),
      ...base,
      ...config,
      setting: defaultSetting,
      goodsSetting,
      shelvesData: { data, shownum: num, source: _source, ...shelvesData },
      data,
      shelvesLink: link
    };
  },
  transformOut: (v) => {
    const result = pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          combg: "combg",
          radius: 'radius',
          contentcolor: 'contentcolor',
          compadded: (v) => {
            return pickBy(v, {
              top: "compadded.paddedt",
              bottom: "compadded.paddedb",
              left: "compadded.paddedl",
              right: "compadded.paddedr",
            });
          },
          show_type: "show_type",
          itempadded: (v) => {
            return pickBy(v, {
              top: "itempadded.paddedt",
              bottom: "itempadded.paddedb",
              left: "itempadded.paddedl",
              right: "itempadded.paddedr",
            });
          },
          itemmargin: (v) => {
            return pickBy(v, {
              bottom: "itemmargin.paddedb",
              right: "itemmargin.paddedr",
            });
          },
          imgPaddedBottom: "imgPaddedBottom",
          imgWidth: "imgWidth",
          showProgress: "showProgress",
          processh: "processh",
          pilot: 'pilot'
        });
      },
      config: (v) => {
        return pickBy(v, {
          showShelves: "showShelves",
          storestyle: "storestyle",
          open_store: "open_store",
          itembg: "itembg",
          border: "border",
          width: "width",
        });
      },
      setting: (v) => {
        return pickBy(v, {
          goodsSetting: "goodsSetting",
          defaultSetting: "setting",
          shelvesLink: 'shelvesLink'
        });
      },
      data: "shelvesData",
    });
    return result;
  },
};
