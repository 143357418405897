// ab模块
import {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
    WgtAroundStore,
  WgtAroundStoreOne,
  WgtFunctionBlock,
  WgtProductShelves,
  WgtContentPart,
  WgtCustomWgt,
  WgtImageText
} from "../wgts/index";

export const initiWidgets = [];

export const widgets = {
  WgtNewSlider,
  WgtSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
    WgtAroundStore,
  WgtAroundStoreOne,
  WgtFunctionBlock,
  WgtProductShelves,
  WgtContentPart,
  WgtCustomWgt,
  WgtImageText
};

export default {
  widgets,
  initiWidgets,
};
